import React from 'react';
import { Unsubscribe } from 'redux';
import copyObject from '../../../infrastructure/helpers/common/copyObject';
import reorderHelper from '../../../infrastructure/helpers/common/reorderHelper';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import arrayItemHelper from '../../../infrastructure/helpers/functions/common/arrayItemHelper';
import outsideContextHelper from '../../../infrastructure/helpers/functions/common/outsideContextHelper';
import autoCompleteHelper from '../../../infrastructure/helpers/functions/condition/autoCompleteHelper';
import conditionHelper from '../../../infrastructure/helpers/functions/condition/conditionHelper';
import conditionModifyHelper from '../../../infrastructure/helpers/functions/condition/conditionModifyHelper';
import customDataSourceHelper from '../../../infrastructure/helpers/functions/customDataSource/customDataSourceHelper';
import lookupHelper from '../../../infrastructure/helpers/functions/lookup/lookupHelper';
import operandHelper from '../../../infrastructure/helpers/functions/operand/operandHelper';
import operandRuleHelper from '../../../infrastructure/helpers/functions/operand/operandRuleHelper';
import overrideOperandHelper from '../../../infrastructure/helpers/functions/operand/overrideOperandHelper/overrideOperandHelper';
import Statement from '../../../infrastructure/types/Functions/Statement';
import actions from '../../../store/actions';
import store from '../../../store/store';
import Condition from '../../../types/functions/Condition/Condition';
import locationType from '../../../types/functions/Location/LocationType';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import OperandRules from '../../../types/functions/Operand/OperandRules';
import validateOperand from '../../Functions/Operand/OperandBuilder.Validation';
import StatementBlockBuilderProps from '../../Functions/Statement/StatementBlockBuilderProps';
import StatementBuilderProps from '../../Functions/Statement/StatementBuilderProps';
import ReportConfigComponentLock from '../../../types/report/ReportConfigComponentLock';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';
import OperandModalState from '../Operand/Modal/OperandModalState';
import getArgumentDefinition from '../../../infrastructure/helpers/functions/common/argumentHelper';
import operand2ExpectedTypeHelper from '../../../infrastructure/helpers/functions/operand/operand2ExpectedTypeHelper';

class StatementBlockBuilder<T extends Statement> extends React.Component<StatementBlockBuilderProps<T>, { showWarningModal: boolean, number: number, setup?: (x: T[]) => void, reportConfigComponentLock?: ReportConfigComponentLock, error?: string }> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: StatementBlockBuilderProps<T>) {
        super(props);

        this.state = {
            showWarningModal: false,
            number: 0,
            setup: () => { },
            reportConfigComponentLock: {} as ReportConfigComponentLock,
            error: ''
        }

        this.onAddStatementClick = this.onAddStatementClick.bind(this);
        this.onRemoveStatementClick = this.onRemoveStatementClick.bind(this);

        this.onOperandClick = this.onOperandClick.bind(this);
        this.onOperandSave = this.onOperandSave.bind(this);
        this.updateOperand = this.updateOperand.bind(this);

        this.onLogicalOperatorChange = this.onLogicalOperatorChange.bind(this);
        this.onParenthesesChange = this.onParenthesesChange.bind(this);
        this.onAddConditionClick = this.onAddConditionClick.bind(this);
        this.onRemoveConditionClick = this.onRemoveConditionClick.bind(this);
        this.onPastePieceOfCodeClick = this.onPastePieceOfCodeClick.bind(this);
        this.reorder = this.reorder.bind(this);
        this.allowPaste = this.allowPaste.bind(this);

        this.onComparisonTypeChange = this.onComparisonTypeChange.bind(this);
        this.onStatementChange = this.onStatementChange.bind(this);
        this.updateCondition = this.updateCondition.bind(this);
        this.onRemoveConfirmed = this.onRemoveConfirmed.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onOperandSave);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    async onAddStatementClick(name: string, setup?: (x: T, y: T[]) => void, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            let statement = this.props.statementHelper.getEmpty(this.props.reportConfig.rawDataSourceId);

            if (name) {
                statement.name = name;
            }

            statement.condition = conditionHelper.add(statement.condition, null);

            this.props.statementHelper.add(this.props.statements, statement);

            if (setup) {
                setup(statement, this.props.statements);
            }

            this.props.onChange(this.props.statements);
        }
    }

    async onRemoveStatementClick(number: number, setup?: (x: T[]) => void, reportConfigComponentLock?: ReportConfigComponentLock, error?: string): Promise<void> {

        this.setState({ showWarningModal: true, number, setup, reportConfigComponentLock, error });
    }

    async onRemoveConfirmed() {

        let target = this.props.statementHelper.getByNumber(this.props.statements, this.state.number);

        if (this.state.error) {
            target.errors = [this.state.error];

            this.props.onChange(this.props.statements);

            return;
        }
        let isLocked = await this.tryLock(this.state.reportConfigComponentLock);
        if (!isLocked) {

            let error = this.props.validateRemoveStatement(this.state.number);
            if (error) {
                target.errors = [error];

                this.props.onChange(this.props.statements);

                return;
            }

            let reduced = this.props.statementHelper.remove(this.props.statements, this.state.number);

            if (this.state.setup) {
                this.state.setup(reduced);
            }

            this.props.onChange(reduced);

            this.setState({ showWarningModal: false });
        }
    }

    async onOperandClick(location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            let statement = this.props.statementHelper.getByNumber(this.props.statements, location.statementNumber);

            let dataSourceId = this.props.statementHelper.getDataSourceId(statement, this.props.reportConfig.rawDataSourceId);

            let operand = conditionHelper.getOperand(statement.condition, location);

            let lookupDataSourceId = location.statement === locationType.statement.lookup ? lookupHelper.getByNumber(this.props.reportConfig.lookups, location.statementNumber).dataSource2Id : null;

            let customDataSourceLegitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, dataSourceId);

            let operandRules = {} as OperandRules;

            let previousLocationFildId = location.fieldId;

            if (location.pieceOfCode === locationType.pieceOfCode.conditionOperand) {

                let condition = conditionHelper.getExactConditionByNumber(statement.condition, location.conditionNumber);

                if (condition !== null && this.props.getOperandRuleBundle) {

                    operandRules = operandRuleHelper.getOperandRules(
                        location,
                        condition,
                        this.props.getOperandRuleBundle,
                        operand,
                        this.props.reportConfig,
                        customDataSourceLegitimacy,
                        this.props.collections
                    )
                } else {

                    let argumentDefinition = getArgumentDefinition(statement.condition, null, location, this.props.reportConfig.dictionaries, this.props.reportConfig.userDefinedFunctions);

                    let expectedType = operand2ExpectedTypeHelper.resolve(statement.condition, location, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields);

                    operandRules = operandRuleHelper.get(
                        argumentDefinition,
                        expectedType,
                        this.props.details.statement,
                        customDataSourceLegitimacy);
                }
            }

            location.fieldId = previousLocationFildId;

            let outsideContextDetails = outsideContextHelper.get(statement.condition, null, location, this.props.reportConfig.lookups, this.props.details.statement);

            let conditionOperand = conditionHelper.getExactConditionOperand(statement.condition, location);
            let callStack = operandHelper.getOperandCallStack(conditionOperand, location.argumentIndexes);
            let arrayItemLegitimacy = arrayItemHelper.getLegitimacy(callStack, operand);

            let state: OperandModalState = {
                title: `Operand ${location.operandNumber}`,
                isOpen: true,
                operand: operandHelper.prepareForEdit(operand, operandRules, outsideContextDetails),
                location: location,
                dataSource1Id: outsideContextDetails.isOutsideContext ? outsideContextDetails.dataSourceId : dataSourceId,
                dataSource2Id: lookupDataSourceId,
                customDataSourceLegitimacy: customDataSourceLegitimacy,
                operandRules: operandRules,
                specialScenario: { doNotPopulate: false, pseudoFunction: null },
                customDataSourceContext: outsideContextDetails.customDataSource,
                error: null,
                arrayItemLegitimacy: arrayItemLegitimacy,
                parameters: null
            };

            this.dispatchStateChange(state);
        }
    }

    onOperandSave(): void {

        let appState = store.getState();
        if (appState.action !== actions.operandModal.save) {
            return;
        }

        let state = appState.operandModalSave;
        if (state.location.statement !== this.props.details.statement) {
            return;
        }

        let error = validateOperand(state.operand, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields, state.operandRules);

        let changes = { error: error } as OperandModalState;

        if (error) {
            this.dispatchStateChange(changes);
            return;
        }

        this.updateCondition(state.location, condition => this.updateOperand(state, condition));

        changes.isOpen = false;

        this.dispatchStateChange(changes);
    }

    updateOperand(state: OperandModalState, condition: Condition): Condition {

        let operand = conditionHelper.getOperand(condition, state.location);

        overrideOperandHelper.override(operand, state.operand, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields, this.props.reportConfig.variables);

        autoCompleteHelper.execute(condition);

        return condition;
    }

    async onLogicalOperatorChange(location: ReportLogicLocation, operator: string, event?: React.ChangeEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            const update = (condition: Condition): Condition => {

                let target = conditionHelper.getByNumber(condition, location.conditionNumber);

                conditionModifyHelper.changeLogicalOperator(target, operator);

                return condition;
            };

            this.updateCondition(location, update);
        }
    }

    async onParenthesesChange(location: ReportLogicLocation, count: number, type: string, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            const update = (condition: Condition): Condition => {

                let target = conditionHelper.getByNumber(condition, location.conditionNumber);

                conditionModifyHelper.changeParenthesesCount(target, count, type);

                return condition;
            };

            this.updateCondition(location, update);
        }
    }

    async onAddConditionClick(location: ReportLogicLocation, operator: string | null, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            const update = (condition: Condition): Condition => conditionHelper.add(condition, operator);

            this.updateCondition(location, update);
        }
    }

    async onRemoveConditionClick(location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            const update = (condition: Condition): Condition => conditionHelper.remove(condition, location.conditionNumber);

            this.updateCondition(location, update);
        }
    }
    async onComparisonTypeChange(location: ReportLogicLocation, value: string, event?: React.ChangeEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            const update = (condition: Condition): Condition => {

                let target = conditionHelper.getByNumber(condition, location.conditionNumber);

                Object.assign(target, { comparisonType: value });

                return condition;
            };

            this.updateCondition(location, update);
        }
    }

    onStatementChange(statement: T): void {

        let target = this.props.statementHelper.getByNumber(this.props.statements, statement.number);

        Object.assign(target, statement);

        this.props.onChange(this.props.statements);
    }

    async onPastePieceOfCodeClick(location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {
            if (!typeHelper.isObject(this.props.clipboard)) {
                return;
            }

            const update = (condition: Condition): Condition => {

                let statement = this.props.statementHelper.getByNumber(this.props.statements, (this.props.clipboard as ReportLogicLocation).statementNumber);

                return copyObject(statement.condition);
            };

            this.updateCondition(location, update);
        }
    }

    async reorder(dragNumber: number, dropNumber: number, event?: React.DragEvent<HTMLDivElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {
            let dragIndex = this.props.statementHelper.findIndex(this.props.statements, dragNumber);
            let dropIndex = this.props.statementHelper.findIndex(this.props.statements, dropNumber);

            reorderHelper.reorder(this.props.statements, dragIndex, dropIndex);

            this.props.onChange(this.props.statements);
        }
    }

    allowPaste(location: ReportLogicLocation): boolean {

        if (!typeHelper.isObject(this.props.clipboard)) {

            return false;
        }

        let proceed =
            (this.props.clipboard as ReportLogicLocation).statement === location.statement &&
            (this.props.clipboard as ReportLogicLocation).pieceOfCode === location.pieceOfCode;

        if (!proceed) {
            return false;
        }

        if ((this.props.clipboard as ReportLogicLocation).statementNumber === location.statementNumber) {

            return false;
        }

        let sameDataSource =
            this.props.statementHelper.getDataSourceId(this.props.statementHelper.getByNumber(this.props.statements, (this.props.clipboard as ReportLogicLocation).statementNumber)) ===
            this.props.statementHelper.getDataSourceId(this.props.statementHelper.getByNumber(this.props.statements, location.statementNumber));

        return sameDataSource;
    }

    updateCondition(location: ReportLogicLocation, update: (condition: Condition) => Condition): void {

        let statement = this.props.statementHelper.getByNumber(this.props.statements, location.statementNumber);

        statement.condition = update(statement.condition);

        this.props.onConditionUpdate(statement);
        this.props.onChange(this.props.statements);
    }

    dispatchStateChange(changes: OperandModalState): void {

        store.dispatch({ type: actions.operandModal.change, payload: changes });
    }

    async tryLock(reportConfigComponentLock?: ReportConfigComponentLock): Promise<boolean> {

        if (reportConfigComponentLock && this.props.onComponentContainerClick) {
            return await this.props.onComponentContainerClick(reportConfigComponentLock);
        }
        return false;
    }

    render(): JSX.Element {
        let props: StatementBuilderProps<T> = {
            isWaiting: this.props.isWaiting,
            isReadOnly: this.props.isReadOnly,
            caseNumber: this.props.caseNumber,
            reportConfig: this.props.reportConfig,
            statements: this.props.statementHelper.getRelevant(this.props.statements),
            collections: this.props.collections,
            counts: this.props.counts,
            onRemoveStatementClick: this.onRemoveStatementClick,
            onLogicalOperatorChange: this.onLogicalOperatorChange,
            onParenthesesChange: this.onParenthesesChange,
            onAddConditionClick: this.onAddConditionClick,
            onRemoveConditionClick: this.onRemoveConditionClick,
            onComparisonTypeChange: this.onComparisonTypeChange,
            onOperandClick: this.onOperandClick,
            onStatementChange: this.onStatementChange,
            onCopyClick: this.props.onClipboardChange,
            onPasteClick: this.onPastePieceOfCodeClick,
            reorder: this.reorder,
            validateRemoveStatement: this.props.validateRemoveStatement,
            allowPaste: this.allowPaste,
            details: this.props.details,
            onDragAndDrop: this.props.onChange,
            onAddStatementClick: this.onAddStatementClick,
            onComponentContainerClick: this.props.onComponentContainerClick
        };

        return (
            <>
                {React.createElement(this.props.childComponentType, props)}
                {this.props.details.name !== 'Lookup' &&
                    !this.props.isReadOnly &&
                    !this.props.isWaiting &&
                    <div className="mt-4 mb-4">
                        <button onClick={() => { this.onAddStatementClick(this.props.details.name) }} className="btn btn-light">
                            + {this.props.details.name}
                        </button>
                    </div>
                }
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onRemoveConfirmed}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Remove item'
                        message=' Are you sure you want to delete this item? This action cannot be undone.' />
                }
            </>
        );
    }
}

export default StatementBlockBuilder;

import { reportType } from '../../../constants/reportType';
import locationType from '../../../../types/functions/Location/LocationType';
import ObjectIndexer from '../../../types/ObjectIndexer';

const htmlIds = {
    dataSources: 'data-sources',
    userDefinedFunctions: 'userDefinedFunctions',
    dictionaries: 'dictionaries',
    cacheKeys: 'cache-keys',
    lookups: 'lookups',
    variables: 'variables',
    aggregations: 'aggregations',
    filters: 'filters',
    endpointFilters: 'endpoint-filters',
    expectedUnderReport: 'expected-under-report',
    expectedOverReport: 'expected-over-report',
    cases: 'cases',
    matchingKeys: 'matching-keys',
    enrichment: 'enrichment',
    validations: 'validations',
    import: 'import',
    bottom: 'bottom',
    cnValidations: 'cn-validations',
    coreValidations: 'core-validations'
};

const buildPrefixMap = (): ObjectIndexer<string> => {
    let map = {} as ObjectIndexer<string>;

    map[locationType.statement.matchingKey] = htmlIds.matchingKeys;
    map[locationType.statement.isinSelector] = htmlIds.cacheKeys;
    map[locationType.statement.leiSelector] = htmlIds.cacheKeys;
    map[locationType.statement.exchangeRatesSelector] = htmlIds.cacheKeys;
    map[locationType.statement.annaDsbUpiEnrichmentSelector] = htmlIds.cacheKeys;
    map[locationType.statement.annaDsbUpiSelector] = htmlIds.cacheKeys;
    map[locationType.statement.firdsFcaInstrumentSelector] = htmlIds.cacheKeys;
    map[locationType.statement.firdsEsmaInstrumentSelector] = htmlIds.cacheKeys;
    map[locationType.statement.fcaRegulatedEntitiesSelector] = htmlIds.cacheKeys;
    map[locationType.statement.lseSelector] = htmlIds.cacheKeys;
    map[locationType.statement.lookup] = htmlIds.lookups;
    map[locationType.statement.variable] = htmlIds.variables;
    map[locationType.statement.aggregation] = htmlIds.aggregations;
    map[locationType.statement.aggregatedRecordField] = htmlIds.aggregations;
    map[locationType.statement.filter] = htmlIds.filters;
    map[locationType.statement.endpointFilter] = htmlIds.endpointFilters;
    map[locationType.statement.underReportFilter] = htmlIds.expectedUnderReport;
    map[locationType.statement.overReportFilter] = htmlIds.expectedOverReport;
    map[locationType.statement.case] = htmlIds.cases;
    map[locationType.statement.reportField] = htmlIds.enrichment;
    map[locationType.statement.validation] = htmlIds.validations;
    map[locationType.statement.validationCore] = htmlIds.coreValidations;
    map[locationType.statement.validationStandard] = htmlIds.cnValidations;
    map[locationType.statement.dictionary] = htmlIds.dictionaries;
    map[locationType.statement.userDefinedFunction] = htmlIds.userDefinedFunctions;
    map[locationType.statement.dictionaryField] = htmlIds.dictionaries;

    return map;
};

const resolveSectionPresence = (htmlId: string, type: string): boolean => {

    switch (htmlId) {

        case htmlIds.aggregations:
        case htmlIds.filters:
        case htmlIds.cases:
        case htmlIds.enrichment:
            return type !== reportType.accuracy;

        case htmlIds.validations:
            return type === reportType.accuracy ;

        case htmlIds.endpointFilters:
        case htmlIds.expectedUnderReport:
        case htmlIds.expectedOverReport:
        case htmlIds.matchingKeys:
            return type === reportType.completenessFieldLevelMatching || type === reportType.completenessRecordLevelMatching;

        default:
            return true;
    }
};

const prefixMap = buildPrefixMap();

export { htmlIds, prefixMap, resolveSectionPresence };

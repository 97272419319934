const functionName = {

    startsWith: 'StartsWith',
    endsWith: 'EndsWith',
    contains: 'Contains',

    toUpper: 'ToUpper',
    toLower: 'ToLower',
    trim: 'Trim',
    trimStart: 'TrimStart',
    trimEnd: 'TrimEnd',
    substring: 'Substring',
    substringLength: 'SubstringLength',
    replace: 'Replace',
    replaceIgnoreCase: 'ReplaceIgnoreCase',
    splitAndPick: 'SplitAndPick',

    sum: 'Sum',
    subtract: 'Subtract',
    multiply: 'Multiply',
    divide: 'Divide',
    power: 'Power',
    absolute: 'Absolute',

    toString: 'ToString',
    toStringBoolean: 'ToStringBoolean',
    toStringDateTime: 'ToStringDateTime',
    toStringDate: 'ToStringDate',
    toStringTime: 'ToStringTime',
    toInteger: 'ToInteger',
    toDecimal: 'ToDecimal',
    toDateTime: 'ToDateTime',

    in: 'In',
    concatenate: 'Concatenate',
    sortAndPickFirst: 'SortAndPickFirst',

    hasValue: 'HasValue',
    convertFromTimeZone: 'ConvertFromTimeZone',
    stripTime: 'StripTime',
    length: 'Length',

    remove: 'Remove',
    removeNonAlphanumeric: 'RemoveNonAlphanumeric',
    inInteger: 'InInteger',
    addTime: 'AddTime',
    addDays: 'AddDays',

    first: 'First',
    last: 'Last',
    sumAggregate: 'SumAggregate',
    count: 'Count',
    countHasValue: 'CountHasValue',
    countDistinct: 'CountDistinct',

    getUtcDateTime: 'GetUtcDateTime',
    activeOnTradingDate: 'ActiveOnTradingDate',

    min: 'Min',
    max: 'Max',

    isDaylightSaving: 'IsDaylightSaving',
    weekDay: 'WeekDay',
    stripMilliseconds: 'StripMilliseconds',

    round: 'Round',
    roundToEven: 'RoundToEven',
    roundUp: 'RoundUp',
    roundDown: 'RoundDown',

    concatenateDelimiter: 'ConcatenateDelimiter',

    padLeft: 'PadLeft',
    padRight: 'PadRight',

    findFirst: 'FindFirst',
    findLast: 'FindLast',

    cnProductMatch: 'CnProductMatch',

    inDelimited: 'InDelimited',

    join: 'Join',

    addDaysBusiness: 'AddDaysBusiness',

    addDaysFx: 'AddDaysFx',

    isIsin: 'IsIsin',

    isAlphabetic: 'IsAlphabetic',

    isNumeric: 'IsNumeric',

    checkDateTimeFormat: 'CheckDateTimeFormat',

    average: 'Average',

    endpointMinTradingDate: 'EndpointMinTradingDate',
    endpointMaxTradingDate: 'EndpointMaxTradingDate',

    fuzziness: 'Fuzziness',

    rate: 'Rate',

    toArray: 'ToArray',
    toStringArrayOfStrings: 'ToStringArrayOfStrings',

    toArrayOfIntegers: 'ToArrayOfIntegers',
    toStringArrayOfIntegers: 'ToStringArrayOfIntegers',

    toArrayOfDecimals: 'ToArrayOfDecimals',
    toStringArrayOfDecimals: 'ToStringArrayOfDecimals',

    toArrayOfBooleans: 'ToArrayOfBooleans',
    toStringArrayOfBooleans: 'ToStringArrayOfBooleans',

    toArrayOfDates: 'ToArrayOfDates',
    toStringArrayOfDates: 'ToStringArrayOfDates',

    toArrayOfTimes: 'ToArrayOfTimes',
    toStringArrayOfTimes: 'ToStringArrayOfTimes',

    toArrayOfDateTimes: 'ToArrayOfDateTimes',
    toStringArrayOfDateTimes: 'ToStringArrayOfDateTimes',

    toArrayOfUnixTimes: 'ToArrayOfUnixTimes',
    toStringArrayOfUnixTimes: 'ToStringArrayOfUnixTimes',

    equal: 'Equal',
    notEqual: 'NotEqual',
    greaterThan: 'GreaterThan',
    lessThan: 'LessThan',
    greaterOrEqualThan: 'GreaterOrEqualThan',
    lessOrEqualThan: 'LessOrEqualThan',
    and: 'And',
    or: 'Or',

    all: 'All',
    any: 'Any',

    allInteger: 'AllInteger',
    anyInteger: 'AnyInteger',

    allDecimal: 'AllDecimal',
    anyDecimal: 'AnyDecimal',

    allBoolean: 'AllBoolean',
    anyBoolean: 'AnyBoolean',

    allDate: 'AllDate',
    anyDate: 'AnyDate',

    allTime: 'AllTime',
    anyTime: 'AnyTime',

    allDateTime: 'AllDateTime',
    anyDateTime: 'AnyDateTime',

    allUnixTime: 'AllUnixTime',
    anyUnixTime: 'AnyUnixTime',

    firstOrdered: 'FirstOrdered',
    lastOrdered: 'LastOrdered',

    toDateTimeString: 'ToDateTimeString',
    isBlank:'IsBlank'
};

export default functionName;

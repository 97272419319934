import cnProductMatch from '../cnProductMatch';
import functionName from './functionName';
import pseudoFunction from '../pseudoFunction';
import SelectOption from '../../../types/SelectOption';

const buildDisplayName = (category: string, name: string): string => {

    return `${category}.${name}`;
};

const functionCategory = {
    string: 'String',
    calc: 'Calc',
    type: 'Type',
    array: 'Array',
    dateTime: 'DateTime',
    aggregation: 'Aggregation',
    firds: 'Misc.FIRDS',
    misc: 'Misc',
    exchangeRates: 'Misc.ExchangeRates',

    operators: 'Operators',
};

const functionOptions: SelectOption[] = [
    {
        value: functionName.contains,
        text: buildDisplayName(functionCategory.string, functionName.contains)
    },
    {
        value: functionName.endsWith,
        text: buildDisplayName(functionCategory.string, functionName.endsWith)
    },
    {
        value: functionName.findFirst,
        text: buildDisplayName(functionCategory.string, functionName.findFirst)
    },
    {
        value: functionName.findLast,
        text: buildDisplayName(functionCategory.string, functionName.findLast)
    },
    {
        value: functionName.length,
        text: buildDisplayName(functionCategory.string, functionName.length)
    },
    {
        value: functionName.padLeft,
        text: buildDisplayName(functionCategory.string, functionName.padLeft)
    },
    {
        value: functionName.padRight,
        text: buildDisplayName(functionCategory.string, functionName.padRight)
    },
    {
        value: functionName.remove,
        text: buildDisplayName(functionCategory.string, functionName.remove)
    },
    {
        value: functionName.removeNonAlphanumeric,
        text: buildDisplayName(functionCategory.string, functionName.removeNonAlphanumeric)
    },
    {
        value: functionName.replace,
        text: buildDisplayName(functionCategory.string, functionName.replace)
    },
    {
        value: functionName.splitAndPick,
        text: buildDisplayName(functionCategory.string, functionName.splitAndPick)
    },
    {
        value: functionName.startsWith,
        text: buildDisplayName(functionCategory.string, functionName.startsWith)
    },
    {
        value: functionName.substringLength,
        text: buildDisplayName(functionCategory.string, functionName.substring)
    },
    {
        value: functionName.toLower,
        text: buildDisplayName(functionCategory.string, functionName.toLower)
    },
    {
        value: functionName.toUpper,
        text: buildDisplayName(functionCategory.string, functionName.toUpper)
    },
    {
        value: functionName.trim,
        text: buildDisplayName(functionCategory.string, functionName.trim)
    },
    {
        value: functionName.trimEnd,
        text: buildDisplayName(functionCategory.string, functionName.trimEnd)
    },
    {
        value: functionName.trimStart,
        text: buildDisplayName(functionCategory.string, functionName.trimStart)
    },
    {
        value: functionName.absolute,
        text: buildDisplayName(functionCategory.calc, functionName.absolute)
    },
    {
        value: functionName.divide,
        text: buildDisplayName(functionCategory.calc, functionName.divide)
    },
    {
        value: functionName.multiply,
        text: buildDisplayName(functionCategory.calc, functionName.multiply)
    },
    {
        value: functionName.power,
        text: buildDisplayName(functionCategory.calc, functionName.power)
    },
    {
        value: functionName.subtract,
        text: buildDisplayName(functionCategory.calc, functionName.subtract)
    },
    {
        value: functionName.sum,
        text: buildDisplayName(functionCategory.calc, functionName.sum)
    },
    {
        value: functionName.round,
        text: buildDisplayName(functionCategory.calc, functionName.round)
    },
    {
        value: functionName.toDecimal,
        text: buildDisplayName(functionCategory.type, functionName.toDecimal)
    },
    {
        value: functionName.toInteger,
        text: buildDisplayName(functionCategory.type, functionName.toInteger)
    },
    {
        value: functionName.toString,
        text: buildDisplayName(functionCategory.type, functionName.toString)
    },
    {
        value: functionName.toDateTime,
        text: buildDisplayName(functionCategory.type, functionName.toDateTime)
    },
    {
        value: functionName.concatenate,
        text: buildDisplayName(functionCategory.array, functionName.concatenate)
    },
    {
        value: functionName.in,
        text: buildDisplayName(functionCategory.array, functionName.in)
    },
    {
        value: functionName.sortAndPickFirst,
        text: buildDisplayName(functionCategory.array, functionName.sortAndPickFirst)
    },
    {
        value: functionName.addDays,
        text: buildDisplayName(functionCategory.dateTime, functionName.addDays)
    },
    {
        value: functionName.addTime,
        text: buildDisplayName(functionCategory.dateTime, functionName.addTime)
    },
    {
        value: functionName.convertFromTimeZone,
        text: buildDisplayName(functionCategory.dateTime, functionName.convertFromTimeZone)
    },
    {
        value: functionName.stripTime,
        text: buildDisplayName(functionCategory.dateTime, functionName.stripTime)
    },
    {
        value: functionName.stripMilliseconds,
        text: buildDisplayName(functionCategory.dateTime, functionName.stripMilliseconds)
    },
    {
        value: functionName.isDaylightSaving,
        text: buildDisplayName(functionCategory.dateTime, functionName.isDaylightSaving)
    },
    {
        value: functionName.weekDay,
        text: buildDisplayName(functionCategory.dateTime, functionName.weekDay)
    },
    {
        value: functionName.getUtcDateTime,
        text: buildDisplayName(functionCategory.dateTime, functionName.getUtcDateTime)
    },
    {
        value: functionName.first,
        text: buildDisplayName(functionCategory.aggregation, functionName.first)
    },
    {
        value: functionName.join,
        text: buildDisplayName(functionCategory.aggregation, functionName.join)
    },
    {
        value: functionName.last,
        text: buildDisplayName(functionCategory.aggregation, functionName.last)
    },
    {
        value: functionName.min,
        text: buildDisplayName(functionCategory.aggregation, functionName.min)
    },
    {
        value: functionName.max,
        text: buildDisplayName(functionCategory.aggregation, functionName.max)
    },
    {
        value: functionName.sumAggregate,
        text: buildDisplayName(functionCategory.aggregation, functionName.sum)
    },
    {
        value: functionName.count,
        text: buildDisplayName(functionCategory.aggregation, functionName.count)
    },
    {
        value: functionName.countDistinct,
        text: buildDisplayName(functionCategory.aggregation, functionName.countDistinct)
    },
    {
        value: pseudoFunction.lei,
        text: buildDisplayName(functionCategory.misc, pseudoFunction.lei)
    },
    {
        value: pseudoFunction.exchangeRates,
        text: buildDisplayName(functionCategory.misc, pseudoFunction.exchangeRates)
    },
    {
        value: pseudoFunction.firds,
        text: buildDisplayName(functionCategory.misc, pseudoFunction.firds)
    },
    {
        value: functionName.activeOnTradingDate,
        text: buildDisplayName(functionCategory.firds, functionName.activeOnTradingDate)
    },
    {
        value: functionName.cnProductMatch,
        text: buildDisplayName(functionCategory.misc, cnProductMatch.displayName)
    },
    {
        value: functionName.hasValue,
        text: buildDisplayName(functionCategory.misc, functionName.hasValue)
    },
    {
        value: functionName.isIsin,
        text: buildDisplayName(functionCategory.misc, functionName.isIsin)
    },
    {
        value: functionName.isAlphabetic,
        text: buildDisplayName(functionCategory.misc, functionName.isAlphabetic)
    },
    {
        value: functionName.isNumeric,
        text: buildDisplayName(functionCategory.misc, functionName.isNumeric)
    },
    {
        value: functionName.checkDateTimeFormat,
        text: buildDisplayName(functionCategory.misc, functionName.checkDateTimeFormat)
    },
    {
        value: functionName.average,
        text: buildDisplayName(functionCategory.aggregation, functionName.average)
    },
    {
        value: functionName.endpointMinTradingDate,
        text: buildDisplayName(functionCategory.dateTime, functionName.endpointMinTradingDate)
    },
    {
        value: functionName.endpointMaxTradingDate,
        text: buildDisplayName(functionCategory.dateTime, functionName.endpointMaxTradingDate)
    },
    {
        value: functionName.fuzziness,
        text: buildDisplayName(functionCategory.string, functionName.fuzziness)
    },
    {
        value: functionName.rate,
        text: buildDisplayName(functionCategory.exchangeRates, functionName.rate)
    },
    {
        value: functionName.toArray,
        text: buildDisplayName(functionCategory.array, functionName.toArray)
    },
    {
        value: functionName.equal,
        text: buildDisplayName(functionCategory.operators, functionName.equal)
    },
    {
        value: functionName.notEqual,
        text: buildDisplayName(functionCategory.operators, functionName.notEqual)
    },
    {
        value: functionName.greaterThan,
        text: buildDisplayName(functionCategory.operators, functionName.greaterThan)
    },
    {
        value: functionName.lessThan,
        text: buildDisplayName(functionCategory.operators, functionName.lessThan)
    },
    {
        value: functionName.greaterOrEqualThan,
        text: buildDisplayName(functionCategory.operators, functionName.greaterOrEqualThan)
    },
    {
        value: functionName.lessOrEqualThan,
        text: buildDisplayName(functionCategory.operators, functionName.lessOrEqualThan)
    },
    {
        value: functionName.and,
        text: buildDisplayName(functionCategory.operators, functionName.and)
    },
    {
        value: functionName.or,
        text: buildDisplayName(functionCategory.operators, functionName.or)
    },
    {
        value: functionName.all,
        text: buildDisplayName(functionCategory.array, functionName.all)
    },
    {
        value: functionName.any,
        text: buildDisplayName(functionCategory.array, functionName.any)
    },
    {
        value: functionName.isBlank,
        text: buildDisplayName(functionCategory.misc, functionName.isBlank)
    },
].sort((prev, next) => {

    const prevText = prev.text.toLowerCase();
    const nextText = next.text.toLowerCase();

    return prevText < nextText ? -1 : prevText > nextText ? 1 : 0;
});

export default functionOptions;
